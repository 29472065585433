import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Card, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { navigationboard_data } from "../../Helpers/Data";
import { bookmarks_data } from "../../Helpers/Data";
import { useKeycloak } from "@react-keycloak/web";
import * as _api from "../../Apis/Me_apis";

const NavigationBoard = () => {
	const { keycloak, initialized } = useKeycloak();
	const [loading, setLoading] = useState(false);
	const [competitionActive, setCompetitionActive] = useState();

	useEffect(() => {
		setLoading(true);
		_api
			.getCompetitionsAsync(keycloak.token)
			.then((res) => {
				res.map((competition) => {
					if (competition.id == localStorage.getItem("competitionid")) {
						setCompetitionActive(competition);
					}
				});
			})
			.then(() => setLoading(false));
	}, [localStorage.getItem("competitionid")]);

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{props}
		</Tooltip>
	);

	const listNavigation = () =>
		navigationboard_data.sort((a, b) => a.order - b.order).map((navigation) => {
			if (navigation.id == 1 && competitionActive && competitionActive.national)
				return null;
			return (
				<NavLink exact to={navigation.route}>
					<Card className="navboard">
						<Card.Body>
							<img className="img_navboard" src={navigation.image} />
							<Card.Text>
								{navigation.title}
								<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTooltip(navigation.tooltip)}
								>
									<span className="tooltip_navboard">?</span>
								</OverlayTrigger>
							</Card.Text>
						</Card.Body>
					</Card>
				</NavLink>
			);
		});

	const listBookmarks = () =>
		bookmarks_data.sort((a, b) => a.order - b.order).map((bookmark) => {
			// chargement du lien du forum si il existe
			if (bookmark.id == 2) {
				if (competitionActive && competitionActive.url_forum) {
					return (
						<a href={competitionActive.url_forum} target="_blank">
							<Card className="navboard">
								<Card.Body>
									<img className="img_navboard" src={bookmark.image} />
									<Card.Text>
										{competitionActive.national ? "Forum" : bookmark.title}
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip(bookmark.tooltip)}
										>
											<span className="tooltip_navboard">?</span>
										</OverlayTrigger>
									</Card.Text>
								</Card.Body>
							</Card>
						</a>
					);
				}
			}
			// chargement du lien du trombinoscope si il existe
			else if (bookmark.id == 6) {
				if (competitionActive && competitionActive.url_trombinoscope) {
					return (
						<a href={competitionActive.url_trombinoscope} target="_blank">
							<Card className="navboard">
								<Card.Body>
									<img className="img_navboard" src={bookmark.image} />
									<Card.Text>
										{bookmark.title}
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip(bookmark.tooltip)}
										>
											<span className="tooltip_navboard">?</span>
										</OverlayTrigger>
									</Card.Text>
								</Card.Body>
							</Card>
						</a>
					);
				}
			}
			// chargement du lien du site internet si il existe
			else if (bookmark.id == 7) {
				if (competitionActive && competitionActive.url_website) {
					return (
						<a href={competitionActive.url_website} target="_blank">
							<Card className="navboard">
								<Card.Body>
									<img className="img_navboard" src={bookmark.image} />
									<Card.Text>
										{bookmark.title}
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip(bookmark.tooltip)}
										>
											<span className="tooltip_navboard">?</span>
										</OverlayTrigger>
									</Card.Text>
								</Card.Body>
							</Card>
						</a>
					);
				}
			}
			// chargement du lien Infrastructure
			else if (bookmark.id == 9) {
				if (
					competitionActive &&
					competitionActive.url_infrastructure_list &&
					competitionActive.national
				) {
					return (
						<a href={competitionActive.url_infrastructure_list} target="_blank">
							<Card className="navboard">
								<Card.Body>
									<img className="img_navboard" src={bookmark.image} />
									<Card.Text>
										{bookmark.title}
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip(bookmark.tooltip)}
										>
											<span className="tooltip_navboard">?</span>
										</OverlayTrigger>
									</Card.Text>
								</Card.Body>
							</Card>
						</a>
					);
				}
			}
			// chargement du lien de la plateforme d'inscription
			else if (bookmark.id == 10) {
				if (
					competitionActive &&
					competitionActive.url_inscription_plateform &&
					competitionActive.national
				) {
					return (
						<a href={competitionActive.url_inscription_plateform} target="_blank">
							<Card className="navboard">
								<Card.Body>
									<img className="img_navboard" src={bookmark.image} />
									<Card.Text>
										{bookmark.title}
										<OverlayTrigger
											placement="right"
											delay={{ show: 250, hide: 400 }}
											overlay={renderTooltip(bookmark.tooltip)}
										>
											<span className="tooltip_navboard">?</span>
										</OverlayTrigger>
									</Card.Text>
								</Card.Body>
							</Card>
						</a>
					);
				}
			} else {
				return (
					<a href={bookmark.route} target="_blank">
						<Card className="navboard">
							<Card.Body>
								<img className="img_navboard" src={bookmark.image} />
								<Card.Text>
									{bookmark.title}
									<OverlayTrigger
										placement="right"
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip(bookmark.tooltip)}
									>
										<span className="tooltip_navboard">?</span>
									</OverlayTrigger>
								</Card.Text>
							</Card.Body>
						</Card>
					</a>
				);
			}
		});

	return (
		<>
			<Nav defaultActiveKey="/" className="">
				{listNavigation()}
				{listBookmarks()}
			</Nav>
		</>
	);
};

export default NavigationBoard;
